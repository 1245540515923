import { MARKETING_HOST } from "./marketing";

export const SUPPORT_HOST = "https://support.proof.com";
export const TITLE_SUPPORT_EMAIL = "titlesupport@proof.com";
export const SUPPORT_EMAIL = "support@proof.com";
export const SUPPORT_PHONE_NUMBER = "1-888-483-4330";
export const SUPPORT_PAGE = `${SUPPORT_HOST}/hc/en-us` as const;

export const PROOF_WORKRAMP_REGISTER_URL = "https://proofacademy.workramp.io/register";
export const QUICK_STAMP_HELP_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/4416007636503-How-to-Use-Quick-Stamp-to-Notarize-Documents` as const;
export const IHN_KNOWLEDGE_ARTICLES_URL = `${SUPPORT_HOST}/hc/en-us` as const;
export const NOTAGIG_ACADEMY_LINK = `${MARKETING_HOST}/academy/notary-onboarding-training` as const;
export const NOTARY_PAYOUT_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360057055154-Notary-Payment-and-Payout` as const;
export const DEVICE_SELECTION_HELP_URL =
  `${SUPPORT_HOST}/hc/en-us/categories/29188633810583-Troubleshooting` as const;
export const DESKTOP_AV_PERMISSIONS_HELP_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/4414775422487` as const;
export const DESKTOP_CAMERA_HELP_URL = `${SUPPORT_HOST}/hc/en-us/articles/4413862395927` as const;
export const MOBILE_AV_PERMISSION_HELP_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/14237306788503` as const;
export const KBA_FAILURE_URL = `${SUPPORT_HOST}/hc/en-us/articles/10468298021271` as const;
export const FIREWALL_TROUBLESHOOTING_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360058955173-Firewall-Troubleshooting` as const;
export const DOCUMENT_SUPPORT_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360056906514-What-Documents-Can-Be-Notarized-on-the-Notarize-Platform` as const;
export const PS1583_ALLOWED_IDS_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360056909474-What-types-of-ID-are-allowed-on-USPS-Form-1583s` as const;
export const PS1583_ALLOWED_SECONDARY_IDS_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360056909474-What-types-of-ID-are-allowed-on-USPS-Form-1583s` as const;
export const ALLOWED_PRIMARY_IDS_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360057120014-What-form-of-ID-is-acceptable-for-online-notarization` as const;
export const BROWSER_SUPPORT_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360056907554-Will-my-Computer-Work-for-Remote-Notarization` as const;
export const TXN_PREP_GUIDE =
  `${SUPPORT_HOST}/hc/en-us/articles/8848570520855-Tagging-Documents` as const;
export const CREATE_A_TEMPLATE_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360057075434-Create-a-Template` as const;
export const TEMPLATE_SUPPORT_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/9916246439063-Document-Templates` as const;
export const EASY_LINK_SUPPORT_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/7980106662807-EasyLink` as const;
export const REFERRALS_SUPPORT_URL = `${SUPPORT_HOST}/hc/en-us/articles/15286351619095` as const;
export const ENCOMPASS_INTEGRATION_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/360062444453-The-Encompass-Integration-for-Lenders` as const;
export const CONDITIONAL_DESIGNATION_SUPPORT_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/12467330642967-Conditional-Fields` as const;
export const MERGE_DOCUMENTS_LEARN_MORE_SUPPORT_URL =
  `${SUPPORT_HOST}/hc/en-us/articles/20775152987927` as const;
export const SELF_SERVICE_SIGNERS_GETTING_STARTED_URL =
  `${SUPPORT_HOST}/hc/en-us/p/self-service` as const;
export const TECHNOLOGY_REQUIREMENTS =
  `${SUPPORT_HOST}/hc/en-us/sections/360010760094-Technology-Requirements-Troubleshooting` as const;

export const DIGITAL_NOTARY_CERT_HELP_URL =
  "https://www.notarize.com/knowledge-center/how-to-acquire-your-digital-certificate-for-notarize";
export const HOW_TO_DOWNLOAD_DIGITAL_CERT_URL =
  "https://www.notarize.com/knowledge-center/how-to-download-your-digital-certificate";
export const KNOWLEDGE_AT_NOTARIZE_URL = "https://www.knowledgeatnotarize.com/";
export const PRIVACY_REQUEST_URL = "https://www.proof.com/resources/privacy-request";
